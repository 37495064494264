import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container mb-8" }
const _hoisted_2 = { class: "main_slider_container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "main_slider_info" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "price-start-from" }
const _hoisted_7 = { class: "place" }
const _hoisted_8 = { class: "container" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-12 col-xxl-2 vert_mid" }
const _hoisted_13 = { class: "col-12 col-xxl-10 vert_mid" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-md-4 mt-2 mb-2 vert_mid" }
const _hoisted_16 = { class: "col-md-6 mt-2 mb-2 vert_mid" }
const _hoisted_17 = { class: "col-md-2 mt-2 mb-2 vert_mid" }
const _hoisted_18 = { class: "container mt-8 mb-8" }
const _hoisted_19 = { class: "section-heading" }
const _hoisted_20 = {
  key: 0,
  class: "row"
}
const _hoisted_21 = { class: "container mb-8 mt-8" }
const _hoisted_22 = { class: "section-heading" }
const _hoisted_23 = { class: "main_slider_container" }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "main_slider_info" }
const _hoisted_26 = { class: "title" }
const _hoisted_27 = { class: "price-start-from" }
const _hoisted_28 = { class: "place" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_EventCard = _resolveComponent("EventCard")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (Object.keys(_ctx.slider_events).length > 0)
        ? (_openBlock(), _createBlock(_component_el_carousel, {
            key: 0,
            interval: 4000,
            trigger: "click",
            type: "card",
            height: "300px"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slider_events, (data, key) => {
                return (_openBlock(), _createBlock(_component_el_carousel_item, { key: key }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: `/event/${data.id}`
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("img", {
                            class: "img-fluid main_img",
                            src: _ctx.site_url +'/'+ data?.slider_image
                          }, null, 8, _hoisted_3),
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, _toDisplayString(data?.name), 1),
                            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('price_start_from')) + ": " + _toDisplayString(data?.price), 1),
                            _createElementVNode("div", _hoisted_7, _toDisplayString(data?.place), 1)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t("search")), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_el_form, {
                id: "kt_modal_new_target_form",
                model: _ctx.filterData,
                class: "form"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_el_form_item, { class: "mb-0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: _ctx.filterData.search,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterData.search) = $event)),
                            placeholder: _ctx.$t('search'),
                            clearable: ""
                          }, null, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_el_date_picker, {
                        class: "dateRangePicker",
                        modelValue: _ctx.filterData.dateRange,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterData.dateRange) = $event)),
                        type: "daterange",
                        "range-separator": "-",
                        "start-placeholder": _ctx.$t('start_date'),
                        "end-placeholder": _ctx.$t('end_date')
                      }, null, 8, ["modelValue", "start-placeholder", "end-placeholder"])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_el_button, {
                          onClick: _ctx.startSearch,
                          type: "primary",
                          round: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("search")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["model"])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('EVENTS')), 1),
      (Object.keys(_ctx.active_events).length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.active_events, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-12 col-lg-4 event-item",
                key: index
              }, [
                _createVNode(_component_EventCard, {
                  data: item,
                  index: index
                }, null, 8, ["data", "index"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.active_events).length === 0)
        ? (_openBlock(), _createBlock(_component_el_empty, {
            key: 1,
            description: _ctx.$t('NOTHING_FOUND')
          }, null, 8, ["description"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('UPCOMING')), 1),
      (Object.keys(_ctx.upcoming_events).length > 0)
        ? (_openBlock(), _createBlock(_component_el_carousel, {
            key: 0,
            interval: 4000,
            trigger: "click",
            height: "300px"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.upcoming_events, (data, key) => {
                return (_openBlock(), _createBlock(_component_el_carousel_item, {
                  "indicator-position": "outside",
                  key: key
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: `/event/${data.id}`
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("img", {
                            class: "img-fluid main_img",
                            src: _ctx.site_url +'/'+ data?.slider_image
                          }, null, 8, _hoisted_24),
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("div", _hoisted_26, _toDisplayString(data?.name), 1),
                            _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t('price_start_from')) + ": " + _toDisplayString(data?.price), 1),
                            _createElementVNode("div", _hoisted_28, _toDisplayString(data?.place), 1)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.upcoming_events).length === 0)
        ? (_openBlock(), _createBlock(_component_el_empty, {
            key: 1,
            description: _ctx.$t('NOTHING_FOUND')
          }, null, 8, ["description"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}