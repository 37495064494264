
import {defineComponent, onMounted, ref, toRaw} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import store from "@/store";
import JwtService from "@/core/services/JwtService";
import {Actions} from "@/store/enums/StoreEnums";
import router from "@/router/router";
import EventCard from "@/components/events/EventCard.vue";

export default defineComponent({
  name: "dashboard",
  components: {
    EventCard
  },

  data() {
    const filterData = {};

    return {
      filterData,
      slider_events: {},
      site_url: process.env.VUE_APP_BACKEND_URL,
      upcoming_events:{},
      active_events:{}
    }
  },
  methods: {
    startSearch() {
      router.push({ name: 'search', params: this.filterData })
    },

    getSliderEvents() {
      const payload = {
        count:8,
        category:'all'
      }
      store.dispatch(Actions.GET_SLIDER_EVENTS,payload).then(response => {
        this.slider_events = response.data;
      }, error => {
        console.error("Got nothing from server. Please check internet connection and try again")
      });
    },
    getUpcomingEvents() {
      store.dispatch(Actions.GET_UPCOMING_EVENTS).then(response => {
        this.upcoming_events = response.data;
      }, error => {
        console.error("Got nothing from server. Please check internet connection and try again")
      });
    },
    getAllActiveEvents() {
      store.dispatch(Actions.GET_CATEGORY_BY_ID,'all').then(response => {
        this.active_events = response.data;
      }, error => {
        console.error("Got nothing from server. Please check internet connection and try again")
      });
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.getSliderEvents();
        this.getUpcomingEvents();
        this.getAllActiveEvents();
      }
    }
  }

});
